@import '../../assests/styles/_brand-colors.scss';

.loading {
    margin-top: 20px;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 100px;
        height: 100px;
    }
}

.survey-section-step {
    border: 1px solid transparent;
}

#feedback-B {
    .survey-section-step {
        hr:first-of-type {
            display: none;
        }
    }

    textarea {
        margin-top: 10px;
        min-height: 100px;
    }

    input[type='textarea'] {
        margin-top: 10px;
        min-height: 100px;
    }

    .base-font-title {
        font-family: Public Sans, serif !important;
        font-size: 32px !important;
        font-weight: 900 !important;
        line-height: 38px !important;
        letter-spacing: 1px !important;
        text-align: left;
    }

    .base-font-body {
        font-family: Public Sans, serif !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        letter-spacing: 0px !important;
        text-align: left;
    }

    .placeholder-font {
        input[type='textarea'],
        input[type='textarea']::placeholder,
        input[type='textarea']:placeholder-shown,
        input,
        input::placeholder,
        input:placeholder-shown {
            font-family: Public Sans, serif !important;
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 24px !important;
            letter-spacing: 0;
        }
    }

    .end-page-font {
        h1 {
            font-family: Calluna !important;
            font-size: 67px !important;
            font-weight: 600 !important;
            line-height: 88px !important;
            letter-spacing: 1px !important;
            text-align: left;
        }

        p {
            span {
                font-family: Public Sans !important;
                font-size: 21px !important;
                line-height: 28px !important;
                letter-spacing: 0.5px !important;
                font-weight: 600 !important;
                text-align: left;
            }

            a {
                font-size: 21px !important;
            }
        }

        a {
            font-family: Public Sans !important;
            font-size: 14px !important;
            font-weight: 800 !important;
            line-height: 24px !important;
            letter-spacing: 0.75px !important;
            text-align: center;
        }
    }

    .sub-title-rating {
        font-weight: 600;
        margin: 0 0 16px 0;
    }

    .sub-title {
        margin: 0;
        font-weight: 800;
    }

    .fix-btn {
        width: 176px;
        margin-left: 70%;
    }

    .btn-font {
        font-family: Public Sans, serif !important;
        font-size: 14px !important;
        font-weight: 800 !important;
        line-height: 24px !important;
        letter-spacing: 0.75px !important;
        text-align: center;
    }

    .category-name {
        font-family: Public Sans, serif !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 24px !important;
        letter-spacing: 0 !important;
        text-align: left;
    }

    .category-description {
        font-family: Public Sans, serif !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        letter-spacing: 0px !important;
        text-align: left;
    }

    .rating_options {
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 16px !important;
        letter-spacing: 0 !important;
    }

    .container {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 0;
        margin: 0;
    }

    .checkbox input {
        font-weight: 600;
        vertical-align: middle;
    }

    .star {
        font-size: 40px;
        fill: none;
    }

    .border-bottom {
        border-bottom: 4px solid #27221d !important;
        padding-bottom: 72px !important;
        margin-bottom: 56px !important;
    }

    .style-recommend-rating {
        font-family: Public Sans, serif !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        border: 0.5px solid #000000 !important;
        min-width: 28px !important;
        min-height: 28px !important;
        background-color: white;
        padding: 0;
    }

    .style-recommend-rating-yellow {
        border: 0.5px solid #000000 !important;
        font-family: Public Sans, serif !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        background-color: #ffd000;
        min-width: 28px !important;
        min-height: 28px !important;
        padding: 0;
    }

    ::selection {
        background: #fcd700;
        color: #fff;
        text-shadow: none;
    }

    .hr-border {
        border: 0.25px solid #707579;
    }

    .text-area-border {
        border: 0.5px solid #000000;
    }

    .required-label {
        font-style: italic;
        font-size: 0.8rem;
        color: gray;
        position: absolute;
        right: 0;
    }

    .feedback-comment {
        font-family: Public Sans, serif !important;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        text-transform: lowercase;

        border: none;
        background: white;
        color: #0078ab;

        div {
            text-transform: lowercase;
        }
    }

    max-width: 600px;
    margin: 0 auto;
    padding: 48px 15px;
    color: $gray-one;

    @media all and (min-width: 768px) {
        padding: 12px 15px;
    }

    p {
        font-size: 20px;
        line-height: 30px;
        margin: 24px 0;
    }

    .review-preview {
        font-size: 15px;
        line-height: 24px;
        margin: 10px 0;

        @media all and (max-width: 768px) {
            padding-bottom: 40px;
        }

        .review-wrapper {
            position: relative;
            z-index: 1;
        }

        p,
        textarea {
            font-size: inherit;
            line-height: inherit;
            width: 100%;
        }

        textarea {
            border: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            opacity: 0;
        }
    }

    h1 {
        font-size: 60px;
        line-height: 69px;
    }

    @media all and (min-width: 768px) {
        h1 {
            font-size: 64px;
            line-height: 72px;
        }
    }

    hr.mt-48 {
        margin-top: 48px;
    }

    img {
        display: block;
        margin: 48px auto;
        max-width: 165px;
        max-height: 112px;
    }

    .yelp-button {
        max-width: 150px;
        border-radius: 2px;
    }

    .panel {
        border: 1px solid $gray-five;
        padding: 8px 24px;
        position: relative;
    }

    .reservation-details {
        display: table;
        width: 100%;
        font-size: 15px;
        line-height: 23px;

        img {
            margin: 0;
            float: left;
            margin-right: 25px;
            max-width: 36%;

            @media all and (min-width: 768px) {
                max-width: 165px;
                max-height: 112px;
            }
        }
    }

    #question-0 {
        div:first-child {
            border-bottom: 0 !important;
        }
    }

    #checkbox-mobile {
        margin-top: 24px;
        display: flex;
    }

    .unit-direction {
        font-style: italic;
    }

    #unit-info-mobile {
        display: inline;
        div:first-child {
            margin-bottom: 8px;
        }
    }

    #checkbox-desktop,
    #unit-info-desktop {
        display: none;
    }

    @media (min-width: 992px) {
        #checkbox-mobile {
            display: none;
        }

        #unit-info-mobile {
            display: none;
            div:first-child {
                margin-bottom: 0;
            }
        }

        #checkbox-desktop {
            display: flex;
        }

        #unit-info-desktop {
            display: inline;
        }
    }

    .question {
        font-size: 24px;
        line-height: 36px;
        margin: 10px 0;
        padding-bottom: 16px;
        border-bottom: 1px solid $gray-five;

        &.no-border-bottom {
            margin: 16px 0 0 0;
        }

        &.last {
            border-bottom: 0;
        }

        .caption {
            align-items: center;
            display: flex;
            gap: 5px;
            font-size: 15px;
            line-height: 24px;

            &.radio {
                margin-top: 10px;
                padding-left: 2px;
            }

            &.checkbox {
                padding-left: 2px;
            }
        }

        .contact-methods,
        .contact-topic {
            margin-top: 24px;
        }

        textarea {
            min-height: 65px;
        }

        .rating {
            display: inline-block;
            position: relative;
            height: 24px;
            line-height: 24px;
            font-size: 24px;
            margin-top: 12px;

            label {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                display: table;
                cursor: pointer;

                &:last-child {
                    position: static;
                }

                &:nth-child(1) {
                    z-index: 5;
                }

                &:nth-child(2) {
                    z-index: 4;
                }

                &:nth-child(3) {
                    z-index: 3;
                }

                &:nth-child(4) {
                    z-index: 2;
                }

                &:nth-child(5) {
                    z-index: 1;
                }

                & input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                }

                & .icon {
                    float: left;
                    font-size: 24px;
                    margin-right: 8px;
                    color: transparent;
                }

                &:last-child .icon {
                    color: $gray-five;
                }
            }

            &:not(:hover) label input:checked ~ .icon,
            &:not(:focus) label input:checked ~ .icon,
            &:hover label:hover input ~ .icon,
            &:focus label:focus input ~ .icon {
                color: $primary;
            }

            & label input:focus:not(:checked) ~ .icon:last-child {
                color: $primary;
                text-shadow: 0 0 5px $primary;
            }
        }

        .fill-star {
            fill: #ffd000;
        }

        .star-rating {
            display: flex;
            margin-top: 13px;
            column-gap: 15px;

            @media (max-width: 370px) {
                column-gap: 1%;
            }

            @media (max-width: 240px) {
                display: inline-flex;
                flex-wrap: wrap;
            }

            & input {
                position: absolute;
                opacity: 0;
            }

            &:not(:hover) label input:checked ~ .star,
            &:not(:focus) label input:checked ~ .star,
            &:hover label:hover input ~ .star,
            &:focus label:focus input ~ .star {
                fill: #ffd000;
            }
        }

        .contact-needed {
            input {
                position: absolute;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                width: 1px;
                border: 0;
                overflow: hidden;
            }

            input:checked + label {
                border-color: $secondary;
                background-color: $secondary-10;
            }

            input:focus + label {
                border-color: $secondary;
                box-shadow: 0 0 0 2px $secondary;
            }
        }

        .new-recommend-rating {
            margin: 8px 0;
            cursor: pointer;
            width: 300px;
            gap: 4px;

            input {
                position: absolute;
                opacity: 0;
            }

            input:checked + label {
                border-color: black;
                background-color: #ffd000;
            }

            input:focus + label {
                background-color: #ffd000;
                box-shadow: 0 0 0 2px #ffd000;
            }
        }

        .recommend-qualifier {
            font-size: 15px;
        }

        #box-indicator-text {
            display: none;
        }

        @media all and (max-width: 768px) {
            .recommend-rating-wrapper {
                display: flex;
                justify-content: center;
                align-content: center;
                margin-top: 8px;

                .new-recommend-rating {
                    display: flex;
                    flex-flow: wrap;
                    justify-content: center;
                    align-content: flex-start;
                    gap: 20px;
                    max-height: 84px;
                    max-width: 292px;

                    .rating-indicator {
                        display: inline;
                        min-height: 32px;
                        min-width: 32px;
                        .indicator-placeholder {
                            display: none;
                        }
                    }
                }
            }

            #box-indicator-text {
                display: flex;
                justify-content: space-around;
                margin-top: 12px;
                max-width: 568px;
                gap: 10px;

                span {
                    font-family: Public Sans, serif !important;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0;
                    text-align: left;
                }
            }
        }

        @media all and (max-width: 500px) {
            #box-indicator-text {
                justify-content: space-between;
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        @media all and (min-width: 769px) {
            .new-recommend-rating {
                display: flex;
                justify-content: space-between;

                label {
                    min-width: 40px;
                }
            }

            .recommend-rating {
                display: flex;
                justify-content: space-between;

                label {
                    min-width: 40px;
                }
            }

            .recommend-qualifier {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .btn-block + .btn-block {
        margin-top: 0 !important;
    }

    .href-link {
        color: $secondary;
        font-size: inherit;
        font-weight: inherit;
        background: none;
        border-radius: 2px;
        line-height: inherit;
        padding: 0;
        margin-left: 3px;
        vertical-align: baseline;
        border: 1px solid transparent;
        transition: ease border 0.2s, ease box-shadow 0.2s;

        &:hover,
        &:focus {
            outline: 0;
            color: $secondary-dark;
            text-decoration: underline;
        }
    }

    .name-change div {
        display: none;
    }

    @media all and (max-width: 768px) {
        .copy-review {
            position: absolute;
            bottom: 24px;
            right: 24px;
            z-index: 2;
        }
    }

    .button {
        font-family: Public Sans, serif !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 24px !important;
        letter-spacing: 0 !important;
        text-align: center;
        background-color: #fab60f !important;
        padding: 4px 20px;
    }

    #question-6 {
        div:first-child {
            margin-bottom: 6px;
        }

        @media all and (max-width: 768px) {
            div:first-child {
                margin-bottom: 0;
            }
        }
    }

    #indicator-placeholder {
        font-family: Public Sans, serif !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;

        &.last-indicator {
            margin-left: -34px;
        }

        @media all and (max-width: 768px) {
            &.last-indicator {
                margin-left: 130px;
            }
        }
    }
}
