@import '~bootstrap/scss/bootstrap.scss';
@import 'assests/styles/_font-styles.scss';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app-container {
    height: 100vh;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
a {
    background-color: transparent;
    color: #0078ab;
    text-decoration: none;
}

#main-content {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
}
.hidden {
    display: none;
}

.body-regular {
    font-family: 'Public Sans', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}
