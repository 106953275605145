.rating-indicator {
    .indicator-placeholder {
        font-size: x-small;
        width: max-content;
        position: fixed;
        font-weight: bolder;
        margin-right: 10px;
        &.last-indicator {
            margin-left: -23px;
        }
    }

    @media all and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: center;

        .indicator-placeholder {
            margin-left: 130px;
            &.last-indicator {
                margin-left: 130px;
            }
        }
    }
}
