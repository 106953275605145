@import '../../../assests/styles/_brand-colors.scss';

$grid-gutter-width: 16px;
$nav-button-color: $midnight;
$border-color: $midnight;
$border-radius: 2px;
$arrow-color: $dusk-lightest;
$hover-fill: $midnight;
$label-color: $midnight;

$height: 56px;
$height-sm: 56px;

$shadow-color: rgba(0, 0, 0, 0.08);
$shadow-color-2: rgba(0, 0, 0, 0.2);
$shadow-color-3: rgba(0, 0, 0, 0.15);

$border-color: $vacasa-yorange;

$input-fill: $dusk-four;
$input-color: $midnight-100;

$divider-color: $gray-six;
$button-border: $midnight;
$svg-color: $midnight-60;
$label-color: $midnight;

$arrow-color: $dusk-light;

@mixin rounded($radius: $border-radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin prefixed($prop, $args...) {
    -webkit-#{$prop}: $args;
    -moz-#{$prop}: $args;
    -ms-#{$prop}: $args;
    -o-#{$prop}: $args;
    #{$prop}: $args;
}

@mixin transition($args...) {
    @include prefixed(transition, $args);
}

@mixin breakpoint($min: 0, $max: 0) {
    $type: type-of($min);

    @if $type == string {
        $class: $min;

        @if $class == xs {
            @media (max-width: 576px) {
                @content;
            }
        } @else if $class == sm {
            @media (min-width: 768px) {
                @content;
            }
        } @else if $class == md {
            @media (min-width: 992px) {
                @content;
            }
        } @else if $class == lg {
            @media (min-width: 1200px) {
                @content;
            }
        } @else {
            @warn "Breakpoint mixin supports: xs, sm, md, lg";
        }
    } @else if $type == number {
        $query: 'all' !default;
        @if $min != 0 and $max != 0 {
            $query: '(min-width: #{$min}) and (max-width: #{$max})';
        } @else if $min != 0 and $max == 0 {
            $query: '(min-width: #{$min})';
        } @else if $min == 0 and $max != 0 {
            $query: '(max-width: #{$max})';
        }
        @media #{$query} {
            @content;
        }
    }
}

.dropdown-menu {
    box-shadow: 0 -5px 25px $shadow-color-3, 0 6px 8px $shadow-color-2;
    border: none;

    &.dropdown-menu-left {
        top: 42px;
        right: -16px;
        left: auto;
    }

    .dropdown-item {
        // total height should be 32px
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        padding: 4px 8px;
        min-width: 260px;
        cursor: pointer;

        .svg-icon {
            font-size: 20px;
            padding-right: 8px;
        }

        span {
            font-size: 16px;

            &.btn-link {
                // phone numbers
                padding-left: 4px;
            }
        }
    }
}

.nav-font-mobile {
    font-family: sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.5;
    color: #2c3439;
    letter-spacing: 0.3px;
}

.nav-font {
    font-family: sans-serif !important;
    font-style: normal;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
}

#navbar-user {
    display: flex;
    width: 100%;
    align-items: stretch;
    background: $white;
    -webkit-font-smoothing: antialiased;

    #navbar-main {
        height: 56px !important;
        line-height: 1.5;
        white-space: nowrap;
        padding-left: 8px;
        padding-right: 8px;

        @include breakpoint(xs) {
            padding-left: 16px;
            padding-right: 16px;
        }

        .navbar-brand {
            margin-right: 0;

            @include breakpoint(md) {
                margin-right: 24px;
            }

            .horizontal-logo {
                width: 120px;
                height: 34px;
            }
        }

        .navbar-nav {
            height: 19px;

            .nav-link {
                color: $label-color;
                font-size: 13px;
                font-weight: 500;
                cursor: pointer;
                padding: 0;

                span {
                    height: 16px;
                }
            }

            .nav-link:hover {
                text-decoration: none;
            }

            .nav-item {
                margin-right: 24px;
                margin-left: 24px;

                .dropdown-menu {
                    font-size: 13px;
                    font-weight: 500;
                    min-width: 200px;
                    padding: 8px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    border-radius: 4px;
                    box-shadow: 0 2px 4px $shadow-color-2, 0 0 1px $shadow-color-2;

                    .dropdown-item {
                        font-size: 13px;
                        font-weight: 500;
                        align-items: center;
                        height: 32px;
                        color: $label-color;
                        -webkit-border-radius: 2px;
                        -moz-border-radius: 2px;
                        border-radius: 4px;
                        min-width: 100px;

                        &:hover {
                            background: $input-fill;
                        }
                    }
                }

                .dropdown-menu.dropdown-menu-left {
                    top: 32px;
                    right: -32px;
                }

                .dropdown-menu:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 70px;
                    box-sizing: border-box;
                    border: 8px solid $black;
                    border-color: $white $white transparent transparent;
                    transform-origin: 0 0;
                    transform: rotate(-45deg);
                    box-shadow: 1px -0.5px 0px 0 $shadow-color-2;
                }

                .dropdown-toggle::after {
                    display: none;
                }
            }
        }

        .navbar-toggler {
            padding: 0 0 0 0;

            .feather {
                height: 24px;
                width: 24px;
                stroke: none;
                fill: #2c3439;
            }

            .feather.inactive {
                display: none;
            }
        }

        .navbar-toggler.hidden {
            visibility: hidden;
            pointer-events: none;
        }
    }

    .sidenavbar {
        transition: all 0.2s;
        background: $white;
        height: 100%;
        max-width: 100vw;
        min-width: 100vw;
        margin-top: $height;
        transform: translateX(0%);
        overflow-y: auto;
        padding-bottom: 60px;

        .sidenavbar-content {
            padding: 24px 8px 16px;
            display: flex;
            flex-direction: column;

            .sidenavbar-item {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .feather {
                    height: 16px;
                    width: 16px;
                    stroke: $svg-color;
                }

                span {
                    font-family: sans-serif !important;
                    font-style: normal;
                    letter-spacing: 0.3px;
                    /* color: $text-color; */
                    color: $input-color;
                    margin-left: 8px;
                }
            }

            .sidenavbar-select-border {
                border-bottom: 0.5px solid rgba(0, 51, 73, 0.5);
                width: 90%;
                margin-bottom: 5px;
            }

            .sidenavbar-select {
                width: 90%;
                background: none !important;
            }

            .sidenavbar-item:hover {
                background: $input-fill;
                text-decoration: none;
            }

            .sidenavbar-divider {
                margin: 16px -8px;
                border-top: 1px solid $divider-color;
            }
        }

        &#sidenavbar-right {
            left: auto;
        }

        &.left-inactive {
            transform: translateX(-100%);
        }

        &.right-inactive {
            transform: translateX(100%);
        }
    }

    .no-scroll {
        overflow-y: hidden;
    }
}

#navbar-user {
    #navbar-main {
        .navbar-nav {
            .nav-item {
                .dropdown-menu.dropdown-menu-left {
                    right: -17px;
                }

                .dropdown-menu:after {
                    right: 16px;
                }
            }
        }
    }
}

.fix-pad {
    padding-bottom: 0;
    padding-top: 0;
}

.hamburger-menu-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.icon-fake {
    height: 24px;
    width: 24px;
    opacity: 0;
}

.light-stroke {
    stroke-width: 0;
}

.icon-head-set {
    height: 16px;
    width: 16px;
}

.icon-caret-down-nav {
    stroke-width: 2;
    height: 16px;
    width: 8px;
    margin-top: 1px;
    margin-left: 4px;
}

@include breakpoint(xs) {
    .no-scroll {
        overflow: hidden;
        height: 100%;
    }
}

.hidden-icon {
    visibility: hidden;
}

#left-icon-menu {
    margin-top: 6px;
    height: 20px !important;
    width: 20px !important;
}

#left-icon-close {
    stroke-width: 0;
    stroke: $midnight;
    fill: $midnight;
    left: 0;
}

.light-stroke {
    stroke-width: 0;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.ml-32,
.mx-32 {
    margin-left: 32px !important;
}

.mr-32,
.mx-32 {
    margin-right: 32px !important;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Only Safari 7.1+  */
_::-webkit-full-page-media,
_:future,
:root .mobile-safari {
    bottom: 8px;
}
/* End Only Safari 7.1+  */

/* Only Firefox */
@-moz-document url-prefix() {
    .mobile-firefox {
        bottom: 3px;
    }
}
/* End Only Firefox */

[tabindex='0'],
a {
    outline: none;
}
