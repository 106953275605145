@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;400;700&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Public, sans-serif;
    color: $black;
    margin-bottom: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
}
