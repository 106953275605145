@import '../../assests/styles/_brand-colors.scss';

.fix-bottom {
    bottom: 0;
}

.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    position: absolute;
    width: 100%;

    color: $midnight;
    background: $midnight-4;
    margin-top: 112px;
    height: 72px;
    padding: 24px;
    left: 0;
    right: 0;
}

.service-links {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
}

.service-links > a {
    color: $midnight-70;
}

.copyright {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: $dusk-lightest;
}
